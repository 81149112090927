export enum State {
  OPTIONS = 'OPTIONS',
  WAITING_RESULTS = 'WAITING_RESULTS',
  RESULTS = 'RESULTS',
  MORE_RESULTS = 'MORE_RESULTS',
  DETAILS = 'DETAILS',
  ERROR = 'ERROR',
}

export enum TaskStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DONE = "DONE",
}

export enum TaskError {
  INVALID_BATCH_SIZE = "INVALID_BATCH_SIZE",
  INVALID_CAPTION = "INVALID_CAPTION",
  BACKEND_FAILURE = "BACKEND_FAILURE",
  BACKEND_TIMEOUT = "BACKEND_TIMEOUT",
  FORBIDDEN_TEXT = "FORBIDDEN_TEXT",
}

export enum TaskItemStatus {
  ACCEPTED = "ACCEPTED",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  FAILED_SERVER = "FAILED_SERVER",
  FAILED_NSFW = "FAILED_NSFW",
}


export const TTI_BASE_URL = "https://aiweb.picsart.com/v2/text-to-image-middleware";
export const POLLING_INTERVAL = 5000;
export const POLLING_TRIES = 24;
export const DAILY_ALLOWANCE = 0;
export const IMAGES_COUNT_PER_REQUEST = 4;
export const KEYWORDS = [
  'Concept art',
  'Illustration',
  'Surrealism',
  'Impressionism',
  'Trending on Artstation',
  'Ultra-realistic',
  'Digital art',
  'Octane render',
  'Highly detailed',
  'Details',
  'Portrait',
  'Cyberpunk',
  'Full HD',
  'HD',
  '8K',
  'Mystery',
  'Hazy',
  'Realistic lighting',
  'Dark fantasy',
  'Hyperrealism',
  'High quality',
  'Cinematic lighting',
  'Unreal engine',
  'V-Ray',
  'Soft lighting',
  'Pastel neon colors',
  'Psychedelic',
  'Digital painting',
  'Smooth',
  'Sharp focus',
  'Rich colors',
  'Vivid colors',
  'Elegant',
  'Centered',
  'Afrofuturism',
  'Watercolor',
  'Hokusai',
  'Contemporary art',
  'Photorealism',
  'Dynamic lighting',
  'Creative',
  'Cinematic',
  'Ultra detailed',
  'Oil on canvas',
  'Artstation',
  'Cartoon',
  '3D',
  'Andy Warhol',
  'Pastel colors',
  'Wide angle',
  'Fantasy',
  'Intricate',
  'Oil painting',
  'Postmodernism',
  'Futuristic',
  'Golden hour',
  'Steampunk',
  'Photography',
  'Stunning visuals',
  'Foggy',
  'Atmospheric',
  'Detailed matte painting',
  'Volumetric light',
  'Neon colors',
];

export const SUGGESTIONS = [
  {
    caption: 'Futuristic robots',
    styles: [
      'Digital art',
      'Cyberpunk',
      'Ultra-realistic',
      'Octane render',
      'Highly detailed',
      'Details',
      'Concept art',
      'Impressionism',
      'Illustration',
      'Mystery',
    ],
  },
  {
    caption: 'Old ruined castle painting',
    styles: [
      'Highly detailed',
      'Details',
      'Illustration',
      'Surrealism',
      'HD',
      'Concept art',
      'Trending on Artstation',
      'Hazy',
      'Realistic lighting',
      'Dark fantasy',
    ],
  },
  {
    caption: 'City made of glass',
    styles: [
      'Concept art',
      'Digital art',
      'Illustration',
      'Octane render',
      'Highly detailed',
      'Details',
      'Cyberpunk',
      'HD',
      '8K',
      'Hyperrealism',
      'Ultra-realistic',
      'Impressionism',
      'Cinematic lighting',
      'Unreal Engine',
      'Trending on Artstation',
      'High Quality',
    ],
  },
  {
    caption: 'Gigantic futuristic buildings',
    styles: [
      'Digital art',
      'Illustration',
      'Octane render',
      'Highly detailed',
      'Cyberpunk',
      'HD',
      '8K',
      'Hyperrealism',
      'Ultra-realistic',
      'Impressionism',
      'Cinematic lighting',
    ],
  },
  {
    caption: 'Stunning portrait of a beautiful female',
    styles: [
      'Highly detailed',
      'Illustration',
      'Cyberpunk',
      'Octane render',
      'Digital art',
      'HD',
      'Soft lighting',
      'Pastel neon colors',
    ],
  },
  {
    caption: 'Cozy sky painting',
    styles: [
      'Watercolor',
      'Hokusai',
      'Contemporary art',
      'Highly detailed',
      'Details',
      '8K',
      'Full HD',
    ],
  },
  {
    caption: 'An alien planet viewed from space',
    styles: [
      'Highly detailed',
      'Photorealism',
      'High quality',
      'Dynamic lighting',
      'Trending on Artstation',
      'Stunning visuals',
      'Creative',
      'Cinematic',
      'Ultra detailed',
    ],
  },
  {
    caption: 'Red Sunset',
    styles: [
      'Photography',
      'Concept art',
      'Octane render',
      'Oil on canvas',
      'Cinematic',
      'Sharp focus',
      'Trending on Artstation',
      'HD',
      '8K',
    ],
  },
  {
    caption: 'River in the forest',
    styles: [
      'Dark fantasy',
      'Photography',
      'Concept art',
      'Octane render',
      'Oil on canvas',
      'Cinematic',
      'Sharp focus',
      'Trending on Artstation',
    ],
  },
  {
    caption: 'Flamingos in Amazon rainforest',
    styles: [
      'Concept art',
      'Octane render',
      'Oil on canvas',
      'Cinematic',
      'Sharp focus',
      'Trending on Artstation',
      'HD',
      '8K',
    ],
  },
  {
    caption: 'Portrait of a cute anime girl',
    styles: ['Illustration', 'Concept art', '8K', 'HD'],
  },
  {
    caption: 'Anime girl, Japan animation city background, night view',
    styles: ['Wide angle', 'Highly detailed', 'Pastel colors'],
  },
  {
    caption: 'Astronaut on the space',
    styles: [
      '3D',
      'Fantasy',
      'Intricate',
      'Highly detailed',
      'Digital painting',
      'Artstation',
      'Illustration',
      'Concept art',
      'Sharp focus',
    ],
  },
  {
    caption: 'Astronaut under the water, bubbles, orange and pink fishes',
    styles: ['Highly detailed', '8K', 'Cyberpunk', 'Illustration'],
  },
  {
    caption:
      'Billboard of a retro speed racer car in the big city with full of neon lights',
    styles: ['Trending on Artstation', 'Ultra detailed', 'Cinematic'],
  },
  {
    caption:
      'A cozy home built in a huge soap bubble, windows, doors, porches, awnings, middle of space, futuristic, cyberpunk lights',
    styles: [
      '8K',
      'HD',
      'Full HD',
      'Octane render',
      'Highly detailed',
      'Details',
      'Surrealism',
      'Ultra detailed',
      'Unreal engine',
      'V-Ray',
    ],
  },
  {
    caption:
      'Ancient ruined temple with stairs and columns lost in deep forest',
    styles: [
      'Mystery',
      'Foggy',
      'Cinematic',
      'Highly detailed',
      'Concept art',
      'Detailed matte painting',
      'Photorealism',
      'Concept art',
      'Volumetric light',
      'Octane render',
      '8K',
      'Trending on Artstation',
      'Cinematic lighting',
      'Ultra Detailed',
      'Hyperrealism',
      'Uplight',
      'Atmospheric',
      'Epic',
      'Mist',
    ],
  },
  {
    caption: 'Small ship in the turbulent sea',
    styles: [
      'Oil painting',
      'Hokusai',
      'Contemporary art',
      'Highly detailed',
      'Details',
      'Full HD',
      '8K',
    ],
  },
  {
    caption: 'Galaxy sky',
    styles: [
      'Oil on canvas',
      'Digital art',
      'Hokusai',
      'Contemporary art',
      'Highly detailed',
      'Details',
      '8K',
      'Full HD',
    ],
  },
  {
    caption: 'Penguin in Sahara',
    styles: [
      'Futuristic',
      'Concept art',
      'Surrealism',
      'Postmodernism',
      'Hyperrealism',
      'Highly detailed',
      'Cinematic lighting',
      'Octane render',
    ],
  },
  {
    caption: 'Sunrise from the top of the mountains',
    styles: [
      'Golden hour',
      'Cinematic',
      'Cinematic lighting',
      'Hazy',
      'Illustration',
      'Digital art',
      'Cartoon',
      'Artstation',
      'Andy Warhol',
    ],
  },
  {
    caption: 'A high-tech solarpunk utopia in the Amazon rainforest',
    styles: [
      'Highly detailed',
      'Ultra-realistic',
      'Full HD',
      '8K',
      'Trending on Artstation',
      'Octane render',
      'Atmospheric',
    ],
  },
  {
    caption: 'Portrait of an elegant cat',
    styles: [
      'Pastel colors',
      'Digital painting',
      'Concept art',
      'Illustration',
      'Afrofuturism',
      'Centered',
    ],
  },
  {
    caption: 'Portrait of adventurous monkey',
    styles: [
      'Concept art',
      'Illustration',
      'Afrofuturism',
      'Digital art',
      'Elegant',
      'Centered',
      'Rich colors',
    ],
  },
  {
    caption: 'Dolphin in a tropical forest under the sea',
    styles: [
      'Futuristic',
      'Concept art',
      'Steampunk',
      'Surrealism',
      'Postmodernism',
      'Hyperrealism',
      'Highly detailed',
      'Cinematic lighting',
      'Octane render',
    ],
  },
  {
    caption: 'Colorful Turtoise under the sea',
    styles: [
      '3D',
      'Fantasy',
      'Atmospheric',
      'Highly detailed',
      'Digital painting',
      'Trending on Artstation',
      'Illustration',
      'Concept art',
      'Ultra-realistic',
    ],
  },
  {
    caption: 'A young  woman with curky long ginger hair and freckles',
    styles: [
      'Highly detailed',
      'Illustration',
      'Cyberpunk',
      'Octane render',
      'Digital art',
      'HD',
      'Soft lighting',
      'Pastel neon colors',
    ],
  },
];
