import { createGenerateId, JssProvider, SheetsRegistry } from "react-jss";
import Main from "./components/Main";

import useStyles from "./useStyles";
import { Spacings, withTabFocus } from '@picsart/cascade';
import { useEffect } from "react";
import { View } from "@picsart/view";
import useThemeChange from "./hooks/useThemeChange";

const registry = new SheetsRegistry();
const generateId = createGenerateId({ minify: false });


const App = () => {
  useStyles();

  useEffect(() => {
    withTabFocus();
  }, []);

  useThemeChange();

  return (
    <JssProvider generateId={generateId} registry={registry}>
      <View
        layout={{
          flexDirection: 'column',
        }}
        spacings={{
          padding: Spacings.s8
        }}
      >
        <Main />
      </View>
    </JssProvider>
  )
}

export default App;
