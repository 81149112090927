import { TTI_BASE_URL } from "../constants";
import genT from "./genT";
import { NetworkError } from "./networkError";

export async function updateImageVisibility(
  id: string,
  visible = true
): Promise<void> {
  const { sid, t } = await genT();

  return fetch(`${TTI_BASE_URL}/v1/photos/visibility`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      platform: "web",
      sid,
      Authorization: `Bearer ${t}`,
    },
    body: JSON.stringify({ id, visibility: visible }),
  })
    .then((res) => Promise.all([res, res.json()]))
    .then(([res, body]) => {
      if (!res.ok) {
        throw new NetworkError("Something went wrong. Please try again later");
      }

      return body;
    });
}
