import { createRoot } from 'react-dom/client';
import { onOpen, getContext } from '@picsart/miniapps-sdk';

import App from './App';
import { checkResetAllowance } from './utils/checkResetAllowance';

onOpen(() => {
  checkResetAllowance(getContext());
  const root = createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <App />,
  );
});

