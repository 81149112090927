import { FontWeights, Typography } from '@picsart/cascade';
import { Button, ButtonSizeLG, ButtonSkinPrimary, ButtonVariants } from '@picsart/button';
import { Theme } from '@picsart/cascade/colors';
import { Text, TypographyHorizontalAlign } from '@picsart/text';

function ErrorScreen(props: { error: string; onRetry: () => void }) {
  const { error, onRetry } = props;

  return (
    <>
      <Text
        size={Typography.t6}
        weight={FontWeights.Bold}
        align={TypographyHorizontalAlign.Center}
        color={Theme.Statuses.Error.base.default}
      >
        Error
      </Text>
      <Text
        size={Typography.t4}
        weight={FontWeights.Regular}
        align={TypographyHorizontalAlign.Center}
        color={Theme.Statuses.Error.base.default}
      >
        {error}
      </Text>
      <Button
        size={ButtonSizeLG}
        variant={ButtonVariants.Text}
        skin={ButtonSkinPrimary}
        onClick={onRetry}
      >
        Refresh and try again
      </Button>
    </>
  );
};

export default ErrorScreen;
