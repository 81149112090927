import { POLLING_TRIES, TTI_BASE_URL, TaskStatus, POLLING_INTERVAL } from "../constants";
import { TaskItem, Task } from "../types";
import genT from "./genT";
import { NetworkError } from "./networkError";

export async function getTaskResult(
  taskId: string,
  triesLeft = POLLING_TRIES
): Promise<TaskItem[]> {
  const { sid, t } = await genT();

  if (triesLeft <= 0) {
    throw new NetworkError("We’re sorry, the connection has timed out");
  }

  const task: Task = await fetch(`${TTI_BASE_URL}/v2/task/${taskId}`, {
    headers: {
      Accept: "application/json",
      sid,
      Authorization: `Bearer ${t}`,
      platform: "web",
      username: "",
      userid: "",
      aiTouchPoint: "ai_text_to_image",
    },
  })
    .then((res) => Promise.all([res, res.json()]))
    .then(([res, body]) => {
      if (!res.ok) {
        throw new NetworkError("Something went wrong. Please try again later");
      }

      return body;
    });

  if (task.status !== TaskStatus.DONE) {
    return new Promise((res) => {
      setTimeout(() => {
        res(getTaskResult(taskId, triesLeft - 1));
      }, POLLING_INTERVAL);
    });
  }

  return task.data;
}
