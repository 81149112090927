import { Text } from '@picsart/text';
import { Image } from '@picsart/image';
import IconInfoCircleOutline from '@picsart/icons/IconInfoCircleOutline';
import { Button, ButtonSizeLG, ButtonVariants } from '@picsart/button';

import { ResultImage } from '../types';

import { FontWeights, Theme, Typography } from '@picsart/cascade';

function Details(props: {
  image: ResultImage;
  onSelect: (image: ResultImage) => void;
  onBack: () => void;
}) {
  const { image, onSelect, onBack } = props;

  return (
    <>
      <Image src={image!.url} />
      <IconInfoCircleOutline />
      <Text size={Typography.t3} color={Theme.Texts.tint2} weight={FontWeights.Regular}>
        This image is non-exclusive content and available to all creators.
      </Text>
      <Button
        variant={ButtonVariants.Filled}
        size={ButtonSizeLG}
        onClick={() => onSelect(image)}
      >
        Edit image
      </Button>
      <Button
        variant={ButtonVariants.Outline}
        size={ButtonSizeLG}
        onClick={onBack}
      >
        Show all results
      </Button>
    </>
  );
};

export default Details;
