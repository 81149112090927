import { TTI_BASE_URL, TaskError } from "../constants";
import { Task } from "../types";
import genT from "./genT";
import { NetworkError } from "./networkError";
import { PromptError } from "./promptError";

export async function requestTextToImage(
  text: string,
  styles?: string[],
  count: number = 4
): Promise<Task> {
  const { sid, t } = await genT();
  return fetch(`${TTI_BASE_URL}/v2/task/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      platform: "web",
      sid,
      Authorization: `Bearer ${t}`,
    },
    body: JSON.stringify({
      caption: text,
      styles: styles,
      batch_size: count,
      task_type: "t2i",
    }),
  })
    .then((res) => Promise.all([res, res.json()]))
    .then(([res, body]) => {
      if (!res.ok) {
        if (body.status === TaskError.FORBIDDEN_TEXT) {
          throw new PromptError(
            "Your description may not contain words or phrases that violate our Community Guidelines"
          );
        }

        if (body.status === TaskError.INVALID_CAPTION) {
          throw new PromptError("Description can’t exceed 77 words");
        }

        throw new NetworkError("Something went wrong. Please try again later");
      }

      return body;
    });
}
