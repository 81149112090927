import { useState, useRef, useCallback, MutableRefObject } from 'react';
import { State, SUGGESTIONS, IMAGES_COUNT_PER_REQUEST, DAILY_ALLOWANCE, KEYWORDS } from '../constants';
import { ResultImage } from '../types';
import { allowanceReached } from '../utils/allowenceReached';
import { decreaseAllowance } from '../utils/decreaseAllowance';
import { getTaskResult } from '../utils/getTaskResult';
import { PromptError } from '../utils/promptError';
import { requestTextToImage } from '../utils/requestTextToImage';
import { updateImageVisibility } from '../utils/uploadIMageVisiblity';
import Details from './Details';
import Results from './Results';
import Error from './Error';
import { Button, ButtonSizeLG, ButtonSizeMD, ButtonSkinPrimary, ButtonSkinSecondary, ButtonVariants } from '@picsart/button';
import { getContext } from '@picsart/miniapps-sdk';
import { Text, TypographyHorizontalAlign } from '@picsart/text';
import { TextArea, TextAreaStatuses } from '@picsart/textarea';
import { FontWeights, Spacings, Theme, Typography } from '@picsart/cascade';
import { View } from '@picsart/view';

function Main() {
  const [appState, setAppState] = useState<State>(State.OPTIONS);
  const [text, setText] = useState('');
  const [style, setStyle] = useState<string[]>([]);
  const [images, setImages] = useState<ResultImage[]>([]);
  const [selectedImage, setSelectedImage] = useState<ResultImage | null>(
    null
  );
  const context = getContext();
  const [limitReached, setLimitReached] = useState(allowanceReached(context));

  const [promptError, setPromptError] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  const errorRetryAction = useRef<null | (() => void)>(null);


  let hasVisibility: MutableRefObject<ResultImage[]> = useRef([]);

  // Run this on each rerender to make sure we display the error message
  if (allowanceReached(context) && !limitReached) {
    setLimitReached(true);
  } else if (!allowanceReached(context) && limitReached) {
    setLimitReached(false);
  }

  // Run this when user want to edit description
  const edit = useCallback(() => {
    setImages([]);
    setAppState(State.OPTIONS);
    setError(null);
    setPromptError(null);
    hasVisibility.current = [];
  }, []);

  const handleError = useCallback((error: Error, retryAction: () => void) => {
    context.handlers.raiseError(error.message);

    if (error instanceof PromptError) {
      setAppState(State.OPTIONS);
      setImages([]);
      setPromptError(error.message);
      return;
    }

    setAppState(State.ERROR);
    setError(error.message);

    errorRetryAction.current = () => {
      errorRetryAction.current = null;
      setError(null);

      retryAction();
    };
  }, [context.handlers]);

  const addImageToCanvas = useCallback(async (image: ResultImage) => {
  
    if (!hasVisibility.current.includes(image)) {
      await updateImageVisibility(image.id);
      hasVisibility.current.push(image);
    }

    const newLayer = await context.layers.images.create([
      {
        url: image!.url,
      },
    ]);

    await context.handlers.send(newLayer);
  }, [context.handlers, context.layers.images]);

  // styles and text auto selecting
  const autoSelect = () => {
    const maxRandom = SUGGESTIONS.length - 1;
    const randomSuggestion =
      SUGGESTIONS[Math.floor(Math.random() * maxRandom)];

    if (text.trim().length === 0) setText(randomSuggestion.caption);
    setStyle([...randomSuggestion.styles]);
  };

  const loadImages = useCallback(
    async (append: boolean = false, stylesList: string[] = []) => {
      if (limitReached) {
        return;
      }
      if (images.length > 0) {
        setAppState(State.MORE_RESULTS);
      } else {
        setAppState(State.WAITING_RESULTS);
      }

      const pendingImages = Array.from({
        length: IMAGES_COUNT_PER_REQUEST,
      }).map(
        (_, i) =>
          ({
            id: `pending-${i + (append ? images.length : 0)}`,
          } as any)
      );

      // Add the pending images 'indicators'
      setImages((oldImages) =>
        append ? [...oldImages, ...pendingImages] : pendingImages
      );
      let requestedText = text;
      let requestedStyles = style.length > 0 ? [...style] : [...stylesList];
      let requestSuccessful = false;
      try {
        const { id: taskId } = await requestTextToImage(
          requestedText,
          requestedStyles,
          IMAGES_COUNT_PER_REQUEST
        );
        if (taskId) setAppState(State.RESULTS);
        const responseImages = await getTaskResult(taskId);

        // Remove the pending images 'indicators' and add results
        setImages((oldImages) =>
          append
            ? [
                ...oldImages.filter((i) => !i.id.startsWith('pending-')),
                ...responseImages,
              ]
            : responseImages
        );

        requestSuccessful = true;
      } catch (e: any) {
        // Remove pending images
        setImages((oldImages) =>
          oldImages.filter((i) => !i.id.startsWith('pending-'))
        );
        handleError(e, () =>
          setAppState(append ? State.RESULTS : State.OPTIONS)
        );
        return;
      }

      // Go back to results screen only when we are still waiting for results
      // so we don't exit for example the details screen on mobile
      setAppState((appState) =>
        appState === State.WAITING_RESULTS ? State.RESULTS : appState
      );

      // Update the allowance status
      if (requestSuccessful && DAILY_ALLOWANCE > 0) {
        decreaseAllowance(context);
        setLimitReached(allowanceReached(context));
      }
    },
    [context, handleError, images.length, limitReached, style, text]
  );

  if (appState === State.ERROR) {
    return (
      <Error
        error={error!}
        onRetry={() => errorRetryAction.current?.()}
      />
    );
  }

  if (appState === State.DETAILS) {
    return (
      <Details
        image={selectedImage!}
        onSelect={async (image) => {
          try {
            await addImageToCanvas(image);
          } catch (e: any) {
            handleError(e, () => setAppState(State.DETAILS));
          }
        }}
        onBack={() => setAppState(State.RESULTS)}
      />
    );
  }

  if (appState === State.RESULTS || appState === State.MORE_RESULTS) {
    return (
      <Results
        images={images}
        onSelect={async (image) => {
          if (!context.handlers.isWeb()) {
            setSelectedImage(image);
            setAppState(State.DETAILS);
            return;
          }

          try {
            await addImageToCanvas(image);
          } catch (e: any) {
            handleError(e, () => setAppState(State.RESULTS));
          }
        }}
        edit={edit}
        loadMore={() => loadImages(true)}
        text={text}
        style={style}
        limitReached={limitReached}
      />
    );
  }

  return (
    <>
      <Text size={Typography.t4} color={Theme.Texts.base} weight={FontWeights.Regular}>
        Describe what’s on your mind. For best results, be specific.
      </Text>
      <View
        spacings={{ marginBlockStart: Spacings.s8 }}
      >
        <TextArea
          value={text}
          customHeight={100}
          onChange={event => {
            setPromptError(null);
            setText((event.target as HTMLTextAreaElement).value);
          }}
          placeholder='Example: Kangaroo carrying a corgi in cartoon style'
          {...(promptError !== null && { status : TextAreaStatuses.Error})}
        />
      </View>
      {promptError && (
        <Text size={Typography.t4} color={Theme.Statuses.Error.base.default} weight={FontWeights.Regular}>
          {promptError}
        </Text>
      )}
      <View
        spacings={{ marginBlockStart: Spacings.s8 }}
      >
        <Button
          isFullWidth
          size={ButtonSizeLG}
          variant={ButtonVariants.Outline}
          skin={ButtonSkinPrimary}
          onClick={() => {
            autoSelect();
          }}
        >
          Auto Select
        </Button>
      </View>
      <View
        spacings={{ marginBlockStart: Spacings.s8 }}
      >
        <Button
          isDisabled={
            appState === State.WAITING_RESULTS ||
            (!style.length && !text.trim())
          }
          isFullWidth
          size={ButtonSizeLG}
          variant={ButtonVariants.Filled}
          skin={ButtonSkinPrimary}
          isLoading={appState === State.WAITING_RESULTS}
          onClick={() => {
            loadImages(false, style);
          }}
        >
          Generate images
        </Button>
      </View>
      {limitReached && (
        <Text
          size={Typography.t4}
          weight={FontWeights.Bold}
          align={TypographyHorizontalAlign.Center}
          color={Theme.Statuses.Error.base.default}
        >
          Daily limit reached
        </Text>
      )}
      {limitReached && (
        <Text
          size={Typography.t4}
          weight={FontWeights.Regular}
          align={TypographyHorizontalAlign.Center}
          color={Theme.Statuses.Error.base.default}
        >
          Come back tomorrow to enjoy more of the AI Image Generator.
        </Text>
      )}
      <View
        spacings={{ marginBlockStart: Spacings.s8 }}
      >
        <Text size={Typography.t4} color={Theme.Texts.tint2} weight={FontWeights.Regular}>
          Tip: Add an artistic style for visual interest.
        </Text>
      </View>
      <View
        spacings={{
          gap: Spacings.s8,
          marginBlockStart: Spacings.s8,
        }}
        // @ts-ignore
        style={{
          flexWrap: 'wrap',

        }}
      >
        {KEYWORDS.map(keyword => (
          <Button
            key={keyword}
            variant={style.includes(keyword) ? ButtonVariants.Filled : ButtonVariants.Outline}
            skin={ButtonSkinSecondary}
            size={ButtonSizeMD}
            onClick={() => {
              if (style.includes(keyword)) {
                setStyle((prevStyle) =>
                  prevStyle.filter((v) => v !== keyword)
                );
              } else {
                setStyle((oldStyle) => [...oldStyle, keyword]);
              }
            }}
          >
            {keyword}
          </Button>
        ))}
      </View>
    </>
  );
};

export default Main;