import { getContext, Theme } from '@picsart/miniapps-sdk';
import { useEffect } from 'react';

const useThemeChange = () => {
  useEffect(() => {
    return getContext().general.theme.subscribe(value => {
      const htmlElement = document.getElementsByTagName('html')[0];
      htmlElement.classList.remove(value === Theme.dark ? Theme.light : Theme.dark);
      htmlElement.classList.add(value);
    }, { needPrevious: true });
  });
};

export default useThemeChange;
