import { Button, ButtonSizeLG, ButtonSkinPrimary, ButtonVariants } from "@picsart/button";
import { FontWeights, Spacings, Theme, Typography } from "@picsart/cascade";
import { Grid } from "@picsart/grid";
import { ImageFrame } from "@picsart/imageframe";
import { TypographyHorizontalAlign, Text } from "@picsart/text";
import { View } from "@picsart/view";
import { TaskItemStatus } from "../constants";
import { ResultImage } from "../types";
import * as Icons from '@picsart/icons';

function Results(props: {
  images: ResultImage[];
  text: string;
  style: string[];
  limitReached: boolean;
  onSelect: (image: ResultImage) => void;
  loadMore: () => void;
  edit: () => void;
}) {
  const {
    text,
    images,
    style,
    limitReached,
    edit,
    onSelect,
    loadMore,
  } = props;

  const pendingResults =
    images.filter((i) => i.id.startsWith('pending-')).length > 0;

  const textForResult =
    text === ''
      ? style.join(', ')
      : style.length !== 0
      ? text + ', ' + style.join(', ')
      : text;

  return (
    <>
      <Text size={Typography.t4} weight={FontWeights.Regular}>
        {pendingResults
          ? 'Retrieving your results…'
          : `Results for "${
              textForResult.length > 290
                ? textForResult.slice(0, 280) + '...'
                : textForResult
            }"`}
      </Text>
      <Grid columns="2fr 2fr" gap={Spacings.s8}>
        {images.map(
          (i) =>
            ({
              id: i.id,
              url: i.status === TaskItemStatus.DONE ? i.url : undefined,
              status: i.status,
              icon:
                i.status === TaskItemStatus.FAILED_NSFW ||
                i.status === TaskItemStatus.FAILED_SERVER
                  ? 'IconWarningCircleOutline'
                  : undefined,
              isSelected: false,
            } as any)
        ).map(image => (
          <View key={image.url}>
            <ImageFrame
              imageUrl={image.url}
              width="auto"
              height="auto"
              icon={Icons[image.icon as keyof typeof Icons]}
              data-testid={image.id}
              isActive={image.isSelected}
              onClick={() => {
                if (image.id.startsWith('pending-')) {
                  return;
                }
              
                if (image.status !== TaskItemStatus.DONE) {
                  return;
                }
              
                onSelect(image);
              }}
              isLoading={image.url == null && image.icon == null}
            />
          </View>
        ))}
      </Grid>
      <View spacings={{ marginBlockStart: Spacings.s8 }}>
        <Button
          isFullWidth
          isDisabled={limitReached || pendingResults}
          isLoading={pendingResults}
          size={ButtonSizeLG}
          variant={ButtonVariants.Outline}
          skin={ButtonSkinPrimary}
          onClick={() => loadMore()}
        >
          Show more results
        </Button>
      </View>
      {!pendingResults && (
        <View spacings={{ marginBlockStart: Spacings.s8 }}>
          <Button
            isFullWidth
            isDisabled={limitReached}
            size={ButtonSizeLG}
            variant={ButtonVariants.Filled}
            skin={ButtonSkinPrimary}
            onClick={edit}
          >
            Edit description
          </Button>
        </View>
      )}
      {limitReached && (
        <View spacings={{ marginBlockStart: Spacings.s8 }}>
          <Text
            size={Typography.t4}
            weight={FontWeights.Bold}
            align={TypographyHorizontalAlign.Center}
            color={Theme.Statuses.Error.base.default}
          >
            Daily limit reached
          </Text>
        </View>
      )}
      {limitReached && (
        <View spacings={{ marginBlockStart: Spacings.s8 }}>
          <Text
            size={Typography.t4}
            weight={FontWeights.Regular}
            align={TypographyHorizontalAlign.Center}
            color={Theme.Statuses.Error.base.default}
          >
            Come back tomorrow to enjoy more of the AI Image Generator.
          </Text>
        </View>
      )}
    </>
  );
};

export default Results;
