import { createUseStyles } from "react-jss";

import { withFontFamily, GilroyFonts } from '@picsart/cascade/fonts';
import { convertThemeToCSS, ThemeLightValues, ThemeDarkValues } from '@picsart/cascade/colors';
import {
  convertEasingToCSS,
  convertRadiusToCSS,
  convertSpacingsToCSS,
  convertTimingsToCSS,
  convertTypographyToCSS,
  EasingValues,
  RadiusValues,
  resetters,
  SpacingsValues,
  tabFocusStyles,
  TimingValues, TypographyValues
} from '@picsart/cascade';


const useStyles = createUseStyles({
  // @ts-ignore
  '@global': {
    ...withFontFamily(GilroyFonts),
    ...resetters,

    ':root': {
      ...tabFocusStyles,
      ...convertSpacingsToCSS(SpacingsValues),
      ...convertTimingsToCSS(TimingValues),
      ...convertRadiusToCSS(RadiusValues),
      ...convertEasingToCSS(EasingValues),
      ...convertTypographyToCSS(TypographyValues),

      '&.light': {
        ...convertThemeToCSS(ThemeLightValues),
      },
      '&.dark': {
        ...convertThemeToCSS(ThemeDarkValues),
      },
    }
  },
}, {
  name: 'gen-ai',
});

export default useStyles;
