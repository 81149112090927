import { v4 as uuidv4 } from "uuid";

import { sha256 } from "./sha256";

const T = "CKHovx6O54";
async function genT() {
  const aSid = uuidv4();
  const auth = await sha256(aSid + T);

  return {
    t: auth,
    sid: aSid,
  };
}

export default genT;
