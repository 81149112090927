import { IGeneralContext } from '@picsart/miniapps-sdk';
import { DAILY_ALLOWANCE } from "../constants";
import { daysSinceEpoch } from "./daysSinceEpoch";

export function checkResetAllowance({ internals }: IGeneralContext) {
  const today = daysSinceEpoch(Date.now());
  if ((internals.state.limit?.day ?? 0) < today) {
    internals.setState(
      {
        ...internals.state,
        limit: { day: today, remaining: DAILY_ALLOWANCE },
      },
      { permanent: true }
    );
  }
}
